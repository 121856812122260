<template>
  <div class="container">
    <div class="profile-header text-center">
      <h5>
        <b
          >Datos de la Institución: {{ institution ? institution.name : "" }}</b
        >
      </h5>
    </div>
    <div class="d-flex m-2 w-100 card-container" v-if="institution">
      <div class="img-container">
        <template v-if="institution && institution.image">
          <ImageEditor
            :key="user_image_key"
            :display_options="false"
            :portrait_size="true"
            :image_id="institution.image"
            :allows_crud="allows_crud"
            @deleted="slotImageDeleted"
          ></ImageEditor>
        </template>
        <template v-else>
          <div class="profile-img-default mb-2">
            <b-icon
              class="default-user-img"
              icon="square"
              variant="secondary"
              scale="6"
            ></b-icon>
          </div>
        </template>
        <div class="d-block">
          <b-button
            @click="$bvModal.show('modal-select-image')"
            size="sm"
            :disabled="!allows_crud"
            class="btn-image-upload mt-2"
            >{{
              institution.image
                ? "Cambiar Foto Institucinal"
                : "Seleccionar Foto Institucional"
            }}</b-button
          >
        </div>
      </div>
      <div class="user-card-info mt-2 w-100">
        <div class="m-1 personal-info-1">
          <template>
            <b-form-group
              label-cols="0"
              label-cols-sm="7"
              label-for="input-min_grade"
              class="my-2 mx-0"
            >
              <template #label>
                <div class="block-title-container">
                  <div>
                    Nota Mínima de la
                    {{
                      $getVisibleNames(
                        "mesh.institution",
                        false,
                        "Institución"
                      )
                    }}:
                  </div>
                  <div class="ml-2" v-if="institution_has_grades">
                    <InfoTooltip
                      :tooltip_text="`Actualmente la Nota Mínima se encuentra bloqueada, si desea cambiar el valor comuníquese con un Administrador`"
                    ></InfoTooltip>
                  </div>
                </div>
              </template>
              <b-form-input
                id="input-min_grade"
                type="number"
                min="0"
                v-model="$v.institution.min_grade.$model"
                :disabled="!allows_crud || institution_has_grades"
                :state="validateState('min_grade')"
                aria-describedby="input-min_grade-feedback"
                size="sm"
              ></b-form-input>
              <b-form-invalid-feedback id="input-min_grade-feedback">
                <div v-if="parseFloat(institution.min_grade) < 0">
                  Este campo debe ser mayor o igual a cero.
                </div>
                <div
                  v-if="
                    parseFloat(institution.min_grade) >=
                    parseFloat(institution.appr_grade)
                  "
                >
                  La Nota Mínima no puede ser mayor o igual que la Nota
                  Aprobatoria
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
          </template>
          <template>
            <b-form-group
              label-cols="0"
              label-cols-sm="7"
              label-for="input-appr_grade"
              class="my-2 mx-0"
            >
              <template #label>
                <div class="block-title-container">
                  <div>
                    Nota Aprobatoria de la
                    {{
                      $getVisibleNames(
                        "mesh.institution",
                        false,
                        "Institución"
                      )
                    }}:
                  </div>
                  <div class="ml-2" v-if="institution_has_grades">
                    <InfoTooltip
                      :tooltip_text="`Actualmente la Nota Aprobatoria se encuentra bloqueada, si desea cambiar el valor comuníquese con un Administrador`"
                    ></InfoTooltip>
                  </div>
                </div>
              </template>
              <b-form-input
                id="input-appr_grade"
                type="number"
                min="0"
                v-model="$v.institution.appr_grade.$model"
                :disabled="!allows_crud || institution_has_grades"
                :state="validateState('appr_grade')"
                aria-describedby="input-appr_grade-feedback"
                size="sm"
              ></b-form-input>
              <b-form-invalid-feedback id="input-appr_grade-feedback">
                <div v-if="parseFloat(institution.appr_grade) < 0">
                  Este campo debe ser mayor o igual a cero.
                </div>
                <div
                  v-if="
                    parseFloat(institution.max_grade) <
                    parseFloat(institution.appr_grade)
                  "
                >
                  La Nota Aprobatoria no puede ser mayor que la Nota Máxima.
                </div></b-form-invalid-feedback
              >
            </b-form-group>
          </template>
          <template>
            <b-form-group
              label-cols="0"
              label-cols-sm="7"
              label-for="input-max_grade"
              class="my-2 mx-0"
            >
              <template #label>
                <div class="block-title-container">
                  <div>
                    Nota Máxima de la
                    {{
                      $getVisibleNames(
                        "mesh.institution",
                        false,
                        "Institución"
                      )
                    }}:
                  </div>
                  <div class="ml-2" v-if="institution_has_grades">
                    <InfoTooltip
                      :tooltip_text="`Actualmente la Nota Máxima se encuentra bloqueada, si desea cambiar el valor comuníquese con un Administrador`"
                    ></InfoTooltip>
                  </div>
                </div>
              </template>
              <b-form-input
                id="input-max_grade"
                type="number"
                min="0"
                v-model="$v.institution.max_grade.$model"
                :disabled="!allows_crud || institution_has_grades"
                :state="validateState('max_grade')"
                aria-describedby="input-max_grade-feedback"
                size="sm"
              ></b-form-input>
              <b-form-invalid-feedback id="input-max_grade-feedback">
                <div v-if="parseFloat(institution.max_grade) < 0">
                  Este campo debe ser mayor o igual a cero.
                </div>
                <div
                  v-if="
                    parseFloat(institution.max_grade) <
                    parseFloat(institution.appr_grade)
                  "
                >
                  La Nota Máxima no puede ser menor que la Nota Aprobatoria.
                </div>
                <div
                  v-if="
                    parseFloat(institution.max_grade) <
                    parseFloat(institution.min_grade)
                  "
                >
                  La Nota Máxima no puede ser menor que la Nota Mínima.
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
          </template>
          <template>
            <b-form-group
              label-cols="0"
              label-cols-sm="7"
              label-for="input-exigence_percentage"
              class="my-2 mx-0"
            >
              <template #label>
                <div class="block-title-container">
                  <div>
                    Porcentaje de Exigencia de la
                    {{
                      $getVisibleNames(
                        "evaluations2.evaluation",
                        false,
                        "Evaluación"
                      )
                    }}:
                  </div>
                  <div class="ml-2" v-if="institution_has_grades">
                    <InfoTooltip
                      :tooltip_text="`Porcentaje de exigencia necesario para que una ${$getVisibleNames(
                        'evaluations2.evaluation',
                        false,
                        'Evaluación'
                      )} se califique con la nota aprobatoria`"
                    ></InfoTooltip>
                  </div>
                </div>
              </template>
              <b-form-input
                id="input-exigence_percentage"
                type="number"
                min="0"
                v-model="$v.institution.exigence_percentage.$model"
                :disabled="!allows_crud || institution_has_grades"
                :state="validateState('exigence_percentage')"
                aria-describedby="input-exigence_percentage-feedback"
                size="sm"
              ></b-form-input>
              <b-form-invalid-feedback id="input-exigence_percentage-feedback"
                >Este campo debe ser mayor o igual a cero y menor o igual a
                100.</b-form-invalid-feedback
              >
            </b-form-group>
          </template>
          <!-- <template>
            <b-form-group
              label-cols="0"
              label-cols-sm="7"
              label-for="input-first_grade_cut"
              class="my-2 mx-0"
            >
              <template #label>
                <div class="block-title-container">
                  <div>Corte de Nota 1:</div>
                  <div class="ml-2">
                    <InfoTooltip
                      :tooltip_text="`Corte de nota 1`"
                    ></InfoTooltip>
                  </div>
                </div>
              </template>
              <b-form-input
                id="input-first_grade_cut"
                type="number"
                min="0"
                v-model="$v.institution.first_grade_cut.$model"
                :disabled="!allows_crud"
                :state="validateState('first_grade_cut')"
                aria-describedby="input-first_grade_cut-feedback"
                size="sm"
              ></b-form-input>
              <b-form-invalid-feedback id="input-first_grade_cut-feedback"
                ><div v-if="parseFloat(institution.first_grade_cut) < 0">
                  Este campo debe ser mayor o igual a cero.
                </div>
                <div
                  v-if="
                    institution.first_grade_cut < institution.second_grade_cut
                  "
                >
                  La Corte de Nota 1 no puede ser menor que el Corte de Nota 2.
                </div></b-form-invalid-feedback
              >
            </b-form-group>
          </template> -->
          <!-- <template>
            <b-form-group
              label-cols="0"
              label-cols-sm="7"
              label-for="input-second_grade_cut"
              class="my-2 mx-0"
            >
              <template #label>
                <div class="block-title-container">
                  <div>Corte de Nota 2:</div>
                  <div class="ml-2">
                    <InfoTooltip
                      :tooltip_text="`Corte de nota 2`"
                    ></InfoTooltip>
                  </div>
                </div>
              </template>
              <b-form-input
                id="input-second_grade_cut"
                type="number"
                min="0"
                v-model="$v.institution.second_grade_cut.$model"
                :disabled="!allows_crud"
                :state="validateState('second_grade_cut')"
                aria-describedby="input-second_grade_cut-feedback"
                size="sm"
              ></b-form-input>
              <b-form-invalid-feedback id="input-second_grade_cut-feedback"
                ><div v-if="parseFloat(institution.second_grade_cut) < 0">
                  Este campo debe ser mayor o igual a cero.
                </div>
                <div
                  v-if="
                    institution.first_grade_cut < institution.second_grade_cut
                  "
                >
                  La Corte de Nota 2 no puede ser mayor que el Corte de Nota 1.
                </div></b-form-invalid-feedback
              >
            </b-form-group>
          </template> -->

          <!-- Segunda parte -->
          <!-- <template>
            <b-form-group
              label-cols="0"
              label-cols-sm="7"
              label-for="input-first_achievement_cut"
              class="my-2 mx-0"
            >
              <template #label>
                <div class="block-title-container">
                  <div>Corte de Logro 1:</div>
                  <div class="ml-2">
                    <InfoTooltip
                      :tooltip_text="`Corte de Logro 1`"
                    ></InfoTooltip>
                  </div>
                </div>
              </template>
              <b-form-input
                id="input-first_achievement_cut"
                type="number"
                min="0"
                v-model="$v.institution.first_achievement_cut.$model"
                :disabled="!allows_crud"
                :state="validateState('first_achievement_cut')"
                aria-describedby="input-first_achievement_cut-feedback"
                size="sm"
              ></b-form-input>
              <b-form-invalid-feedback
                id="input-first_achievement_cut-feedback"
              >
                <div
                  v-if="
                    parseFloat(institution.first_achievement_cut) < 0 ||
                    parseFloat(institution.first_achievement_cut) > 100
                  "
                >
                  Este campo debe ser mayor o igual a cero y menor o igual a
                  100.
                </div>
                <div
                  v-if="
                    institution.first_achievement_cut <
                    institution.second_achievement_cut
                  "
                >
                  El Corte de Logro 1 no puede ser menor que el Corte de Logro
                  2.
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
          </template> -->
          <!-- <template>
            <b-form-group
              label-cols="0"
              label-cols-sm="7"
              label-for="input-second_achievement_cut"
              class="my-2 mx-0"
            >
              <template #label>
                <div class="block-title-container">
                  <div>Corte de Logro 2:</div>
                  <div class="ml-2">
                    <InfoTooltip
                      :tooltip_text="`Corte de Logro 1`"
                    ></InfoTooltip>
                  </div>
                </div>
              </template>
              <b-form-input
                id="input-second_achievement_cut"
                type="number"
                min="0"
                v-model="$v.institution.second_achievement_cut.$model"
                :disabled="!allows_crud"
                :state="validateState('second_achievement_cut')"
                aria-describedby="input-second_achievement_cut-feedback"
                size="sm"
              ></b-form-input>
              <b-form-invalid-feedback
                id="input-second_achievement_cut-feedback"
              >
                <div
                  v-if="
                    parseFloat(institution.second_achievement_cut) < 0 ||
                    parseFloat(institution.second_achievement_cut) > 100
                  "
                >
                  Este campo debe ser mayor o igual a cero y menor o igual a
                  100.
                </div>
                <div
                  v-if="
                    institution.first_achievement_cut <
                    institution.second_achievement_cut
                  "
                >
                  El Corte de Logro 2 no puede ser mayor que el Corte de Logro
                  1.
                </div></b-form-invalid-feedback
              >
            </b-form-group>
          </template> -->
          <!-- 3 -->
          <template>
            <b-form-group
              label-cols="0"
              label-cols-sm="7"
              label-for="input-hours_per_credit"
              class="my-2 mx-0"
            >
              <template #label>
                <div class="block-title-container">
                  <div>Horas por Crédito SCT:</div>
                  <div class="ml-2">
                    <InfoTooltip
                      :tooltip_text="`Valor por el que se divide ''${$getVisibleNames(
                        'manual.total_pedagogical_hours',
                        false,
                        'Total de Horas Pedagógicas'
                      )}'' para determinar la cantidad de ''${$getVisibleNames(
                        'manual.credits',
                        false,
                        'Crédito SCT'
                      )}''`"
                    ></InfoTooltip>
                  </div>
                </div>
              </template>
              <b-form-input
                id="input-hours_per_credit"
                type="number"
                min="0"
                v-model="$v.institution.hours_per_credit.$model"
                :disabled="!allows_crud"
                :state="validateState('hours_per_credit')"
                aria-describedby="input-hours_per_credit-feedback"
                size="sm"
              ></b-form-input>
              <b-form-invalid-feedback id="input-hours_per_credit-feedback"
                >Este campo debe ser mayor o igual a
                cero.</b-form-invalid-feedback
              >
            </b-form-group>
          </template>
          <!-- <template>
            <b-form-group
              label-cols="0"
              label-cols-sm="7"
              label-for="input-max_semester_amount"
              class="my-2 mx-0"
            >
              <template #label>
                <div class="block-title-container">
                  <div>
                    Niveles del
                    {{
                      $getVisibleNames(
                        "mesh.egressprofile",
                        false,
                        "Perfil de Egreso"
                      )
                    }}
                    (valor por defecto):
                  </div>
                  <div class="ml-2">
                    <InfoTooltip
                      :tooltip_text="` Cantidad de niveles sugeridos durante la creación de un ${$getVisibleNames(
                        'mesh.egressprofile',
                        false,
                        'Perfil de Egreso'
                      )}. Influye en la estructura del Plan de Estudio y la Malla Curricular (Siempre y cuando no existan ''${$getVisibleNames(
                        'mesh.cycle',
                        true,
                        'Ciclos Formativos'
                      )}'' asociados).`"
                    ></InfoTooltip>
                  </div>
                </div>
              </template>
              <b-form-input
                id="input-max_semester_amount"
                type="number"
                min="0"
                v-model="$v.institution.max_semester_amount.$model"
                :disabled="!allows_crud"
                :state="validateState('max_semester_amount')"
                aria-describedby="input-max_semester_amount-feedback"
                size="sm"
              ></b-form-input>
              <b-form-invalid-feedback id="input-max_semester_amount-feedback"
                >Este campo debe ser mayor o igual a 1.</b-form-invalid-feedback
              >
            </b-form-group>
          </template> -->
          <template>
            <b-form-group
              label-cols="0"
              label-cols-sm="7"
              label-for="input-module_minutes"
              class="my-2 mx-0"
            >
              <template #label>
                <div class="block-title-container">
                  <div>Minutos por Hora Pedagógica:</div>
                  <div class="ml-2">
                    <InfoTooltip
                      :tooltip_text="`Valor utilizado para la conversión de horas pedagógicas a cronológicas en las ${$getVisibleNames(
                        'mesh.egressprofilematter',
                        true,
                        'Asignaturas'
                      )} que no cuenten con un ${$getVisibleNames(
                        'mesh.egressprofile',
                        false,
                        'Perfil de Egreso'
                      )} asociado`"
                    ></InfoTooltip>
                  </div>
                </div>
              </template>
              <b-form-input
                id="input-module_minutes"
                type="number"
                min="0"
                v-model="$v.institution.module_minutes.$model"
                :disabled="!allows_crud"
                :state="validateState('module_minutes')"
                aria-describedby="input-module_minutes-feedback"
                size="sm"
              ></b-form-input>
              <b-form-invalid-feedback id="input-module_minutes-feedback"
                >Este campo debe ser mayor o igual a 1.</b-form-invalid-feedback
              >
            </b-form-group>
          </template>
          <!-- <template>
            <b-form-group
              label-cols="0"
              label-cols-sm="7"
              label-for="global_reach"
              class="label m-0 align-middle mb-2"
            >
              <template #label>
                <div class="block-title-container">
                  <div>Visión Completa por Defecto.</div>
                  <div class="ml-2">
                    <InfoTooltip
                      :tooltip_text="`Determina si al momento de crear un usuario, este tiene acceso a todos los recursos disponibles, o se deben otorgar manualmente`"
                    ></InfoTooltip>
                  </div>
                </div>
              </template> -->
          <!-- <template #label> -->
          <!-- <div class="weighing-label">
                  <div class="label-content">
                    Todos los Permisos por Defecto. -->
          <!-- <span>
                      <InfoTooltip
                  class="info-icon"
                  :tooltip_text="`Permite la asignación de un ${$getVisibleNames(
                    'teaching.basera',
                    false,
                    'RA Base'
                  )} ya relacionado a otra ${$getVisibleNames(
                    'mesh.egressprofilematter',
                    false,
                    'Asignatura'
                  )}.
                  `"
                >
                </InfoTooltip>
                    </span> -->
          <!-- </div>
                </div>
              </template> -->
          <!-- <div id="global_reach" class="align-middle">
                <div class="d-flex">
                  <div class="toggle-types noprint">
                    <span
                      :class="{
                        'font-weight-bold': institution.global_reach,
                      }"
                      >Si</span
                    >
                    <b-button
                      class="check-type"
                      :disabled="!allows_crud"
                      @click="
                        () => {
                          institution.global_reach = !institution.global_reach;
                        }
                      "
                      variant="primary"
                    >
                      <b-icon
                        icon="circle-fill"
                        class="icon-check"
                        :class="{
                          'icon-check-active': !institution.global_reach,
                        }"
                        scale="0.7"
                      ></b-icon>
                    </b-button>
                    <span
                      :class="{
                        'font-weight-bold': !institution.global_reach,
                      }"
                      >No</span
                    >
                  </div>
                </div>
              </div>
            </b-form-group>
          </template> -->
          <div class="row">
            <div class="col" style="text-align: right" v-if="allows_crud">
              <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-select-image"
      title="Seleccionar Imagen"
      hide-footer
      size="md"
    >
      <ImageSelector @created="slotImageCreated"></ImageSelector>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { toast } from "@/utils/utils";

// minLength,
import {
  required,
  minValue,
  maxValue,
  // numeric,
} from "vuelidate/lib/validators";
export default {
  name: "InstitutionCard",
  mixins: [validationMixin],
  components: {
    ImageEditor: () => import("@/components/reusable/ImageEditor"),
    ImageSelector: () => import("@/components/reusable/ImageSelector"),
    InfoTooltip: () => import("@/components/reusable/InfoTooltip"),
  },
  props: {
    allows_crud: {
      type: Boolean,
    },
  },
  data() {
    return {
      user_image_key: 0,
      institution: null,
      institution_has_grades: false,
    };
  },
  validations: {
    institution: {
      min_grade: {
        required,
        // numeric,
        minValue: minValue(0),
        is_valid() {
          if (
            parseFloat(this.institution.min_grade) <=
            parseFloat(this.institution.appr_grade)
          )
            return true;
          else return false;
        },
      },
      appr_grade: {
        required,
        // numeric,
        is_valid() {
          if (
            parseFloat(this.institution.appr_grade) <
            parseFloat(this.institution.max_grade)
          )
            return true;
          else return false;
        },
        minValue: minValue(0),
      },
      max_grade: {
        required,
        // numeric,
        is_valid() {
          if (
            parseFloat(this.institution.max_grade) >
              parseFloat(this.institution.appr_grade) &&
            parseFloat(this.institution.max_grade) >
              parseFloat(this.institution.min_grade)
          )
            return true;
          else return false;
        },
        minValue: minValue(0),
      },
      first_grade_cut: {
        required,
        // numeric,
        is_valid() {
          if (
            parseFloat(this.institution.first_grade_cut) <=
            parseFloat(this.institution.second_grade_cut)
          )
            return true;
          else return false;
        },
        minValue: minValue(0),
      },
      second_grade_cut: {
        required,
        // numeric,
        is_valid() {
          if (
            parseFloat(this.institution.first_grade_cut) <=
            parseFloat(this.institution.second_grade_cut)
          )
            return true;
          else return false;
        },
        minValue: minValue(0),
      },
      exigence_percentage: {
        required,
        // numeric,
        minValue: minValue(0),
        maxValue: maxValue(100),
      },
      first_achievement_cut: {
        required,
        // numeric,
        is_valid() {
          if (
            parseFloat(this.institution.first_achievement_cut) <=
            parseFloat(this.institution.second_achievement_cut)
          )
            return true;
          else return false;
        },
        minValue: minValue(0),
        maxValue: maxValue(100),
      },
      second_achievement_cut: {
        required,
        // numeric,
        is_valid() {
          if (
            parseFloat(this.institution.first_achievement_cut) <=
            parseFloat(this.institution.second_achievement_cut)
          )
            return true;
          else return false;
        },
        minValue: minValue(0),
        maxValue: maxValue(100),
      },
      hours_per_credit: {
        required,
        // numeric,
        minValue: minValue(0),
      },
      max_semester_amount: {
        required,
        // numeric,
        minValue: minValue(1),
      },
      module_minutes: {
        required,
        // numeric,
        minValue: minValue(1),
      },
    },
  },
  computed: {
    ...mapGetters({
      Institution: "getInstitution",
    }),
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.institution[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.institution.$touch();
      if (this.$v.institution.$anyError) {
        return;
      }
      const payload = {
        id: this.institution.id,
        item: {
          min_grade: this.institution.min_grade,
          appr_grade: this.institution.appr_grade,
          max_grade: this.institution.max_grade,
          first_grade_cut: this.institution.first_grade_cut,
          second_grade_cut: this.institution.second_grade_cut,
          exigence_percentage: this.institution.exigence_percentage,
          first_achievement_cut: this.institution.first_achievement_cut,
          second_achievement_cut: this.institution.second_achievement_cut,
          hours_per_credit: this.institution.hours_per_credit,
          global_reach: this.institution.global_reach,
          max_semester_amount: this.institution.max_semester_amount,
          module_minutes: this.institution.module_minutes,
        },
      };
      this.$restful
        .Patch(`/mesh/institution/${payload.id}/`, payload.item)
        .then((response) => {
          this.institution = response;
          this.$store.commit("setUserInstitution", response);
          toast("Institución Actualizada");
        });
    },
    slotImageDeleted() {
      const payload = {
        id: this.institution.id,
        item: { image: null },
      };
      this.$restful
        .Patch(`/mesh/institution/${payload.id}/`, payload.item)
        .then((response) => {
          this.institution = response;
          this.$store.commit("setUserInstitution", response);
          this.$store.commit("setInstitutionLogo", null);
          toast("Se Elimino el Logo de la Institución ");
        });
    },
    slotImageCreated(image) {
      if (image) {
        const payload = {
          id: this.institution.id,
          item: { image: image.id },
        };
        this.$restful
          .Patch(`/mesh/institution/${payload.id}/`, payload.item)
          .then((response) => {
            this.institution = response;
            this.$store.commit("setUserInstitution", response);
            if (response.image) {
              this.$store
                .dispatch(names.FETCH_IMAGE, response.image)
                .then((image) => {
                  this.$store.commit("setInstitutionLogo", image.image);
                  toast("Se Actualizo el Logo de la Institución ");
                });
            }
          });
      }
      this.$bvModal.hide("modal-select-image");
    },
  },
  mounted() {
    this.institution = { ...this.Institution };
    this.$restful.Get(`/teaching/institution_has_grades/`).then((response) => {
      this.institution_has_grades = response;
    });
  },
  created() {},
};
</script>

<style scoped>
.block-title-container {
  display: flex;
  justify-content: space-between;
}
.profile-header {
  /* position: absolute; */
  margin: auto;
  width: 50%;
  top: 6%;
  border-bottom: 0.1px solid #7ebacc;
  width: 25rem;
}
.img-container {
  align-self: center;
  display: block;
  width: 30%;
}
.default-user-img {
  transform: translateY(210%);
}
.btn-image-upload {
  width: 70%;
}
.card-container {
  background-color: var(--primary-hover-color);
  box-shadow: 1px 1px 7px #9cb0b1;
  border-radius: 5px;
  padding: 4rem;
}
.personal-info-1,
.personal-info-2 {
  width: 100%;
  margin-left: 2.8rem !important;
  text-align: left;
}
.profile-img-default {
  width: 5.5em;
  height: 5.5em;
  background: #ddd;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10%;
}
.weighing-label {
  display: flex;
}
.label-content {
  width: max-content;
}
.total-modules-label > .label-content {
  max-width: 7rem;
}
.user-card-info {
  display: flex;
  /* width: 70%; */
}
.info-icon {
  position: relative;
  vertical-align: top;
  height: 12px;
  width: 12px;
}
.toggle-types {
  display: flex;
  float: right;
  /* margin-right: 1em; */
  /* background: rgb(230, 230, 230); */
  padding: 0.3em 2em;
  border-radius: 3px;
}
.check-type {
  background: #007bff !important;
  margin-inline: 0.7em;
  height: 1.13em;
  width: 2.2em !important;
  margin-top: 2%;
  border-radius: 10px;
}
.icon-check {
  color: white;
  transform: translate(-69%, -43%);
  transition: 0.2s;
}
.icon-check-active {
  transform: translate(15%, -43%) !important;
}
@media (max-width: 780px) {
  .user-card-info {
    display: block;
  }
  .personal-info-1,
  .personal-info-2 {
    width: 85%;
  }
  .btn-image-upload {
    width: 50%;
  }
}
@media (max-width: 530px) {
  .card-container {
    display: block !important;
    padding: 2em 0.2em;
  }
  .img-container,
  .personal-info-1,
  .personal-info-2 {
    width: 100%;
  }
  .btn-image-upload {
    width: 50%;
  }
}
</style>